import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ImageSlider = (props) => {
    const { images } = props;
    const [settings] = React.useState({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    });

    return (
        <Slider {...settings}>
            {images.map((src, i) => {
                if (!src)
                    return null;

                // const _imgUrl = `${prefix}_${i}.png`;

                return <img
                    className="img-store"
                    key={i}
                    src={src}
                    alt="매장사진"
                    // alt={data._name}
                    // onError={(e)=> { e.onError = null; e.target.className="img-store"; e.target.src= _imgUrl }}/>
                    onError={(e) => { e.target.onError = null; e.target.className = "img-store no-img"; e.target.src = "/img-512-maskable.png" }} />
            })}
        </Slider>
    );
}