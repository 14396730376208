import * as React from 'react';
import { NavLink } from "react-router";
import ReactGA from 'react-ga';
import ttContext from '../class/AppContext.jsx';
import { AuthButton, StarButton } from './Account.jsx';
import ReviewList from './Reviews.jsx';
// import EventList from './Events.jsx';
import InstructorList from './Lesson.jsx';
import { InfoTab, TableTab } from './StoreInfoTabs.jsx';

const StoreInfo = (props) => {
    const { backend, user } = React.useContext(ttContext);
    const [ meta ] = React.useState(backend.meta);
    const [ showSignIn, setShowSignIn ] = React.useState(false);
    const [ tabs, setTabs ] = React.useState([true, false, false, false]);
    const [ state, setState ] = React.useState({ 
        isLoading: true,
        data: props.data
    });

    const onToggleTab = (tabIdx) => {
        tabs.forEach((_, i) => tabs[i] = (i === tabIdx)? true: false);
        setTabs([...tabs]);
        setShowSignIn(false);

        ReactGA.event({
            category: 'Click',
            action: 'onToggleTab',
            label: tabIdx.toString()
        });
    }

    const onClose = () => {
        setState({ data: null, isLoading: true });
        props.onKeyClear();
    }

    const onNavigate = (key) => {
        onClose();
        props.mapPanTo(key);
    }

    const sendKakaoLink = (_data) => {
        backend.sendKakaoLink(_data);

        ReactGA.event({
            category: 'Share',
            action: 'sendKakaoLink',
            label: _data._id
        });
    }

    const onStar = (failed) => {
        if (failed) {
            setTabs([false, false, false, false]);
            setShowSignIn(true);
        }
    }

    React.useEffect(() => {
        setTabs([true, false, false, false, false]);
        setShowSignIn(false);

        setState({
            isLoading: props.data? false : true,
            data: props.data,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);


    React.useEffect(() => {
        setShowSignIn(false);
    }, [user]);

    const { data, isLoading } = state;
    
    if (isLoading || !data) {
        return (
            <section className="search-info visual">
                <div className="loading-wrap"><div className="icon-loading"></div></div>
                <div className="search-info-control">
                    <NavLink to={''} onClick={onClose} className="btn-close">
                        <i className="far fa-times-circle"></i>
                    </NavLink>
                </div>
            </section>
        );
    } else {
        const _tel = data._tel? data._tel : '전화번호 미제공';
        const _address = data._address? data._address.address_name + (data._address.building_name? ' ' + data._address.building_name : '') : '없음';
        const _hasReviews = (data._tags && data._tags.includes("review"))? true : false;
        // const _hasTeetimes = (data._tags && data._tags.includes("teetime"))? true : false;
        const _showProfiles = (data._info.instructors && data._info.instructors.length > 0);

        return (
            <section className="search-info visual">
                <div className="search-info-header">
                    <h2>{data._name}</h2>
                    <div className="search-info-control">
                        <button onClick={onClose} className="btn-close">
                            <i className="far fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <ul className="ul-info">
                    <li>
                        <button className="btn" onClick={() => {
                            ReactGA.event({ category: 'Click', action: 'onNavigate' });
                            onNavigate(data._id);
                        }}>
                            <i className="fas fa-map-marker-alt"></i>
                        </button>
                        <span>{_address}</span>
                        {/* <input type="text" value={_address} style={{ width : ((_address.length*10)+35)+'px' }} readOnly={true} /> */}
                    </li>
                    <li>
                        <a href={data._tel? `tel:${data._tel}` : 'tel:000'} onClick={() => ReactGA.event({ category: 'Click', action: 'tel' })} className={`btn ${data._tel? '':'false'}`}>
                            <i className="fas fa-phone"></i>
                        </a>
                        <span className={data._tel? '': 'false'}>{_tel}</span>
                    </li>
                </ul>
                <ul className="ul-tabs social">
                    <li className="social">
                        <StarButton data={data} onClick={onStar} />
                    </li>
                    <li className="social">
                        <button type="button" onClick={() => sendKakaoLink(data)} className={"btn share"}><i className="fas fa-comment"></i><span>공유하기</span></button>
                    </li>
                    {/* { data._tags.includes('reserve') &&
                    <li className="tab">
                        <button type="button" onClick={onToggleBooking} className={`btn book ${showBooking}`}><i className="far fa-clock"></i><span>예약</span></button>
                    </li>
                    } */}
                </ul>
                <ul className="ul-tabs horizontal">
                    <li className="tab">
                        <button type="button" onClick={() => onToggleTab(0)} className={`btn info ${tabs[0]}`}><i className="fas fa-bullseye"></i><span>기본정보</span></button>
                    </li>
                    <li className="tab">
                        <button type="button" onClick={() => onToggleTab(2)} className={`btn review ${tabs[2]}`}>
                            <span>
                                리뷰
                                { _hasReviews && <div className="badge">{data._stat.reviews}</div> }
                            </span>
                        </button>
                    </li>
                    {/* <li className="tab">
                        <button type="button" onClick={() => onToggleTab(3)} className={`btn event ${tabs[3]}`}>
                            <span>
                                스벙
                                { _hasTeetimes && <span className={'dot teetime'}></span> }
                            </span>
                        </button>
                    </li> */}
                    { _showProfiles &&
                    <li className="tab">
                        <button type="button" onClick={() => onToggleTab(3)} className={`btn profile ${tabs[3]}`}><i className="fas fa-info-circle"></i><span>강사진</span></button>
                    </li>
                    }
                </ul>
                <section className={"section-tab"}>
                    { tabs[0] && <InfoTab data={data} meta={meta} /> }  
                    { tabs[1] && <TableTab data={data} meta={meta} /> }
                    { tabs[2] &&
                        <div className="reviews">
                            <ReviewList data={data} />
                        </div>
                    }
                    {/* { tabs[3] && 
                        <div className="events">
                            <EventList id={data._id} info={data} />
                        </div>
                    } */}
                    { tabs[3] && 
                        <div className="lesson">
                            <InstructorList data={data._info.instructors} />
                        </div>
                    }
                    { showSignIn && 
                        <div className="message">
                            <span className="span-msg">로그인하시고 관심 골프장으로 등록하세요.</span>
                            <AuthButton />
                        </div>
                    }  
                </section>
            </section>
        );
    }
}

export default StoreInfo;