import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp, collection, orderBy, doc, where, limit, query, startAt, endAt, getDocs, getDoc, setDoc, addDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithPhoneNumber, signInWithCustomToken, signOut } from "firebase/auth";
import { getStorage, uploadBytesResumable, ref } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import { geohashQueryBounds, distanceBetween } from 'geofire-common';
import Kakao from '../class/Kakao.jsx';
import meta from '../meta.json';

export default class Backend {

  static myInstance = null;

  static getInstance = (props) => {
    if (this.myInstance == null)
      Backend.myInstance = new Backend(props);

    return this.myInstance;
  }

  constructor(props) {
    this.props = props;

    const app = initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_ID,
      measurementId: process.env.REACT_MEASUREMENT_ID
    });
  
    this.fst = getFirestore(app);
    this.auth = getAuth(app);
    this.auth.languageCode = 'ko';
    this.authConfirmationResult = null;

    this.dataRef = collection(this.fst, "_data"); 
    this.statRef = collection(this.fst, "_stat");
    this.teetimeRef = collection(this.fst, "_teetime");
    this.usersRef = collection(this.fst, "_users");
    this.reviewsRef = collection(this.fst, "_reviews");
    this.logsRef = collection(this.fst, "_log");

    this.imageStorage = getStorage(app);

    this.user = {};
    this.kakao = new Kakao();

    this.usersRefListner = null;
    this.meta = meta;

    this.onFBAuthStateChanged();
  }

  /////////////////////////////////////////////////////////////////////////////////

  _setUser = (_user) => {
    this.user = _user;
    this.props.setUser(_user);
    window.OTKT.setUser({ uid: _user?.uid })
  }

  _setData = (_data) => {
    this.props.setData(_data);
    this.props.setIsLoading(false);
  }

  /////////////////////////////////////////////////////////////////////////////////

  getCurrentPosition = () => {
    this.props.setIsLoading(true);

    if (navigator && navigator.geolocation) {
        return new Promise((resolve, reject) => {
          const coordTimeout = setTimeout(() => reject(false), 2500);

          navigator.geolocation.getCurrentPosition(
              (position) => {
                  clearTimeout(coordTimeout);
                  resolve([position.coords.latitude, position.coords.longitude]);
              },
              () => reject(false),
              {maximumAge: 10000, timeout: 2500, enableHighAccuracy: false}
          );
        });
    }
    else {
        return new Promise((_, reject) => reject(false));
    }
  }

  /////////////////////////////////////////////////////////////////////////////////

  filterData = (data, tags) => {
    data.forEach((row) => {
      row.hidden = false;

      const matched = Object.keys(tags).every((k) => {
          const tvals = tags[k];
          const val = k in row? row[k] : null;
          
          return (val && (
                  (Array.isArray(val) && tvals.every(tval => val.includes(tval))) // _tags AND
                  || (tvals.includes(val))    // _ttype OR
              ));
      });

      row.hidden = !matched;
    });

    this._setData([...data]);
  }

  /////////////////////////////////////////////////////////////////////////////////

  _cleanupKeys = (_info) => {
    Object.keys(_info).forEach((k) => {
      if (k.substring(0, 1) === '_') {
        const _k = k.substring(1);
        _info[_k] = _info[k];
        delete _info[k];
      }
    });

    return _info;
  }

  fetchRow = (_id) => {
    return getDoc(doc(this.dataRef, _id))
      .then((doc) => {
        if (doc.exists)
          return { '_id': doc.id, ...doc.data() };
        else
          throw new Error(`fetchRow no data - ${_id}`);
      })
      .then((data) => this.processRow(data))
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  getRows = (rows) => {
    let ps = [];

    rows.forEach((row) => {
      let p = this.fetchRow(row.id);
      ps.push(p);
    });

    return Promise.all(ps)
      .then((rows) => {
        if (!rows || rows.length === 0)
          return this._setData([]);

        const data = rows.filter((row) => (row && row._ttype !== undefined));

        this._setData(data);
      })
      .catch((err) => {
        console.error(err);
        this._setData([]);
      });    
  }

  processRow = (data) => {
    delete data._data;
    data._info = this._cleanupKeys(data._info);

    return getDoc(doc(this.statRef, data._id))
      .then((res) => {
        const row = res.data();
        
        if (row && row.reviews && row.reviews > 0)
          data._tags.push('review');
        if (row && row.teetimes && row.teetimes > 0)
          data._tags.push('teetime');
        
        data._stat = row;
         
        return data;
      })
      .catch(() => {
        data._stat = null;
        return data;
      });
  }

  keySearch = (_id) => {
    return this.fetchRow(_id)
      .then((row) => {
        if (row) {
          this._setData([row]);
          return row;
        }
        else {
          this._setData([]);
          throw new Error(null);
          //return null;
        }
      });
  }

  keywordSearch = (keyword, callbackFn = () => {}) => {
    this.props.setIsLoading(true);

    const _url = 'https://OJ2ZPZP81S-dsn.algolia.net/1/indexes/teetime.cc/query';

    const _opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Algolia-Application-Id': 'OJ2ZPZP81S',
        'X-Algolia-API-Key': '40e5721d5c67bd462daa6fce5882b76a'
      },
      body: JSON.stringify({ params: `query=${keyword}&hitsPerPage=15&getRankingInfo=0` })
    };

    return fetch(_url, _opts)
      .then(res => res.json())
      .then((res) => {
        return res.hits;
      })
      .then((rows) => {
        if (rows.length > 0 && rows[0] && rows[0].lat && rows[0].lng)
          callbackFn([parseFloat(rows[0].lat),parseFloat(rows[0].lng)]);  // Geolocation
        else
          this.getRows(rows.map((row => ({ ...row, id: row.objectID }))));  // Stores
      })
      .catch((err) => {
        console.error(err);
        this._setData([]);
      });
  }

  geoSearch = (geo) => {
    this.props.setIsLoading(true);

    let ps = [];
    const bounds = geohashQueryBounds(geo.center, 1000);

    for (const b of bounds) {
      const q = query(this.dataRef, orderBy('_geo.geohash'), startAt(b[0]), endAt(b[1]))
      ps.push(getDocs(q));
    }

    Promise.all(ps)
      .then((snapshots) => {
        let pss = [];
        
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const _data = doc.data();
            const _coords = [_data._geo.geopoint._lat, _data._geo.geopoint._long]
            const _dist = distanceBetween(_coords, geo.center) * 1000;
            
            let _row = this.processRow({ _id: doc.id, ..._data, _dist: _dist });
            pss.push(_row);
          }
        }

        return Promise.all(pss).catch(() => new Error('processRow error'));
      })
      .then((data) => {
        data.sort((a, b) => a._dist - b._dist);
        this._setData(data);
      })
      .catch((e) => {
        console.error(e);
        this._setData([]);
      }); 
  }

  ///////////////////////////////////////////////////////////////////////////

  reviewsQuery = (_sid) => {
    if (!this.user)
      return new Promise((resolve) => resolve(null));

    return getDocs(query(this.reviewsRef, where("_sid", "==", _sid), orderBy('_ts', 'desc'), limit(10)))
      .then((qs) => {
        let posts = [];
        qs.forEach((doc) => {
          posts.push({ _id: doc.id, ...doc.data() });
        });

        return posts;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  insertReview = (_data) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));
    
    return addDoc(this.reviewsRef, {
        ..._data,
        _uid: this.user.uid,
        _ts: serverTimestamp()
      })
      .then((r) => {
        window.OTKT.sendEvent({ event: 'FORM_SUBMIT', id: _data._sid, params: _data })
        return true;
      })
      .catch((err) => new Promise((resolve, reject) => reject(err)));
  }

  deleteReview = (_id) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));
    
    return deleteDoc(doc(this.reviewsRef, _id))
      .then(() => {
        return true;
      })
      .catch((err) => new Promise((_, reject) => reject(err)));
  }

  uploadImage = (file) => {
    const id = uuidv4();
    const filename = id + '.' + file.name.split('.')[1];

    return uploadBytesResumable(ref(this.imageStorage, `images/${filename}`), file);
  }

  ///////////////////////////////////////////////////////////////////////////

  setStar = (_data) => {
    if (!this.user || !this.user.uid)
      return new Promise((_, reject) => reject(false));
    
    const addStar = () => {
      const row = {
        id: _data._id,
        name: _data._name,
        starred: true,
        ts: serverTimestamp()
      };

      return setDoc(doc(this.usersRef, this.user.uid, '_stars', _data._id), row, {merge: true})
        .then(() => this.onUserInfo())
        .then(() => true)
        .catch((e) => {
          console.error(e);
          return false;
        });
    };

    // if (_force)
    //   return addStar();
    
    return getDoc(doc(this.usersRef, this.user.uid, '_stars', _data._id))
      .then((doc) => {
        if (doc.exists())
          return deleteDoc(doc.ref)
            .then(() => this.onUserInfo())
            .then(() => false)
            .catch((e) => {
              console.error(e);
              return false;
            });
        else
          return addStar();
      })
      .catch((e) => {
        console.error(e);
        return false;
        // return addStar();
      });
  }

  ///////////////////////////////////////////////////////////////////////////

  // eventsQuery = (_id) => {
  //   return getDocs(query(this.teetimeRef, where('status', '==', 0)))
  //     .then((qs) => {
  //       let _teetime = [];

  //       qs.forEach((doc) => {
  //         _teetime.push({...doc.data(), '_id': doc.id});
  //       });

  //       return _teetime;
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return [];
  //     });
  // }

  // insertEvent(_data) {
  //   if (!this.user || !this.user.uid)
  //     return;

  //   const row = {
  //     uid: this.user.uid,
  //     name: _data.name,
  //     date: _data.date,
  //     time: _data.time,
  //     size: _data.size,
  //     contact: _data.contact,
  //     status: 0,
  //     ts: FieldValue.serverTimestamp()
  //   };

  //   return addDoc(this.teetimeRef.collection(_data._id), row)
  //     .then(() => {
  //       return this.setStar(_data.info, true)
  //         .then(() => true)
  //         .catch(() => true);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return false;
  //     });
  // }

  // updateEvent(_id, _data) {
  //   if (!_id || _data.status === undefined)
  //     return new Promise((_, reject) => reject());

  //   return this.teetimeRef.collection(_id).doc(_data._id)
  //     .set({
  //       status: _data.status,
  //       uts: FieldValue.serverTimestamp()
  //     }, { merge : true })
  //     .then((r) => {
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return false;
  //     });
  // }

  // reserve = (_id, _data) => {
  //   if (!this.user || !this.user.uid)
  //     return new Promise((resolve, reject) => reject(false));

  //   const row = {
  //     sid: _id,
  //     uid: this.user.uid,
  //     name: _data.name,
  //     date: _data.date,
  //     //time: _data.time,
  //     period: _data.period,
  //     hour: _data.hour,
  //     min: _data.min,
  //     size: _data.size,
  //     contact: _data.contact,
  //     request: _data.request === "1"? _data.requestTxt : _data.request,
  //     ts: FieldValue.serverTimestamp()
  //   };

  //   return this.fst.collection("_reserve").add(row)
  //     .then(() => {
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return false;
  //     });    
  // }

  // getAnalysis = (docId) => {
  //   return this.analysisRef.doc(docId).get()
  //     .then((doc) => {
  //       return doc.data();
  //     });
  // }

  ///////////////////////////////////////////////////////////////////////////

  onFBAuthStateChanged = () => {
    onAuthStateChanged(
      this.auth,
      (user) => {
        if (user) {
          this.user = { uid: user.uid, phoneNumber: user.phoneNumber, email: user.email };
          this.onUserInfo();
        }
        else {
          this._setUser(null);
        }
      },
      (err) => {
        console.error('onAuthStateChanged', err);
        this.signOut();
      }
    );
  }

  onUserInfo = () => {
    if (this.usersRefListner)
      this.usersRefListner();
      
    if (!this.user || !('uid' in this.user) || !this.user.uid) {
      this.signOut();
      return;
    }

    this.usersRefListner = onSnapshot(doc(this.usersRef, this.user.uid), (snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.data();
          // console.log('onUserInfo', data);

          getDocs(collection(snapshot.ref, '_stars'))
            .then((qs) => {
              const stars = qs.docs;
              data._stars = stars.length > 0? stars.map((star) => ({ id: star.id, ...star.data() })) : [];
              data._stars.sort((f, s) => f.name > s.name? 1: -1);
            })  
            .catch((err) => {
              console.error('usersRefListner', err);
              data._stars = [];
              return [];
            })
            .then((_) => {
              this._setUser({ ...this.user, _info: { _name: data._name, _stars: data._stars } });
            });
        } else {
          this._setUser({ ...this.user, _info: { _name: null, _stars: [] } });
        }
      },
      (err) => {
        console.error(err);
        this.signOut();
      });
  }

  updateUserInfo = (data) => {
    if (!this.user || !this.user.uid)
      return new Promise((resolve, reject) => reject(false));

    return setDoc(doc(this.usersRef, this.user.uid), { ...data }, { merge : true })
      .then(() => true)
      .catch((err) => console.error(err));
  }

  signInWithPhone = (phoneNumber, appVerifier) => {
    return signInWithPhoneNumber(this.auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        return true;
      }).catch((error) => {
        console.error(error);
        window.confirmationResult  = null;
        return false;
      });
    }
    
  phoneAuthConfirm = (code) => {
    return window.confirmationResult.confirm(code)
      .then((result) => {
        this.user = result.user;
        this.onUserInfo();
        window.OTKT.sendEvent({ event: 'SIGNIN', params: result.user });
        return true;
      }).catch((error) => {
        console.error(error);
        return false;
      });
  }

  signInWithKakao = () => {
    return this.kakao.signIn()
      .then((token) => {
        return signInWithCustomToken(this.auth, token)
          .then((user) => {
            if (!user.user || !user.user.email || !user.user.emailVerified)
              throw new Error('signInWithCustomToken - invalid user');
            else {
              this.user = user.user;
              this.onUserInfo();
              window.OTKT.sendEvent({ event: 'SIGNIN', params: user })
              return true;
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }).catch((err) => {
        console.error(err);
        this.kakao.signOut();
        return false;
      });
  }

  signOut() {
    if (this.usersRefListner)
        this.usersRefListner();

    return signOut(this.auth)
      .then(() => {
        this._setUser(null);
        window.OTKT.sendEvent({ event: 'SIGNOUT' })
      })
      .catch((err) => {
        console.error('signOut', err);
        this._setUser(null);
      });
  }

  //////////////////////////////////////////////////////
  
  sendKakaoLink = (data) => {
    this.kakao.sendKakaoCustomLink(data);
  }
}