import React from 'react';
import { Route, Routes, BrowserRouter } from "react-router";

import './App.css';
import { AppContextProvider } from './class/AppContext.jsx';
import Main from './components/Main.jsx';

const App = () => {
  return (
      <AppContextProvider>
        <BrowserRouter>
            <Routes>
              <Route path="*" element={<Main />} />
              {/* <Route exact path="/oauthNaver" render={(props) => <Auth {...props} />} /> */}
            </Routes>
        </BrowserRouter>
      </AppContextProvider>
  );
}

export default App;