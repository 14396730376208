import React from 'react';
import ReactGA from 'react-ga';

import ttContext from '../class/AppContext.jsx';
import Account from './Account.jsx';
import SearchControl from './SearchControl.jsx';
import SearchForm from './SearchForm.jsx';
import SearchList from './SearchList.jsx';

const SidebarHeader = (props) => {
    const { toggleSidebar, showSidebar } = props;

    return (
        <React.Fragment>
            <div className="sidebar-footer">
                <ul className="ul-footer">
                    <li><h1>티타임</h1></li>
                </ul>
                <ul className="ul-btns">
                    <li>
                        <button className="btn btn-toggle" onClick={toggleSidebar} title="메뉴">
                            {showSidebar && <i className="far fa-times-circle"></i>}
                            {!showSidebar && <i className="fas fa-bars"></i>}
                        </button>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

const SidebarMenu = (props) => {
    //const { backend } = React.useContext(ttContext);
    const { curMenu, selectMenu } = props;

    return (
        <div className="sidebar-menu">
            <ul className="ul-menu">
                <li className={curMenu === 'Search' ? 'true' : ''}>
                    <button className="btn" onClick={() => selectMenu('Search')}><span>골프장</span></button>
                </li>
                <li className={curMenu === 'Account' ? 'true' : ''}>
                    <button className="btn" onClick={() => selectMenu('Account')}><span>관심 골프장</span></button>
                </li>
                <li>
                    <a className="btn" href="https://talk.teetime.cc">{/*<i className="far fa-smile"></i>*/}<span>커뮤니티</span></a>
                </li>
            </ul>
        </div>
    );
}

const Search = (props) => {
    const { key } = React.useContext(ttContext);
    const { keywordSearch, onTagSelect, onKeySelect, refreshMap } = props;
    const [showSearch, setShowSearch] = React.useState(true);
    const [showFilter, setShowFilter] = React.useState(false);

    const _toggleSidebar = () => {
        setShowSearch(true);
        setShowFilter(true);

        props.toggleSidebar();

        ReactGA.event({
            category: 'Click',
            action: 'ShowMap'
        });
    }

    return (
        <React.Fragment>
            {showSearch && <SearchForm keywordSearch={keywordSearch} />}
            {showFilter && <SearchControl key={key} onTagSelect={onTagSelect} />}
            <div className="search-form-option">
                <h2><span>{props.location}</span></h2>
                <button type="button" className="btn btn-map" onClick={_toggleSidebar}>지도보기</button>
                <button type="button" className={`btn btn-filter ${showFilter}`} onClick={() => setShowFilter((s) => !s)}>필터</button>
                {/* <button type="button" className={`btn btn-search ${showSearch}`} onClick={() => setShowSearch((s) => !s)}>검색</button> */}
            </div>
            <SearchList onKeySelect={onKeySelect} refreshMap={refreshMap} />
        </React.Fragment>
    );
};

const Sidebar = (props) => {
    const { location, toggleSidebar, showSidebar, keywordSearch, onTagSelect, onKeySelect, keyQuery, refreshMap } = props;
    const [curMenu, setMenu] = React.useState('Search');

    const _selectMenu = (menu) => {
        setMenu(menu);

        ReactGA.event({
            category: 'Click',
            action: menu
        });
    };

    const _toggleSidebar = () => {
        setMenu('Search');
        toggleSidebar();
    }

    return (
        <section className={"section-contents " + showSidebar}>
            <section className={"sidebar " + showSidebar}>
                <SidebarHeader toggleSidebar={_toggleSidebar} showSidebar={showSidebar} />
                <SidebarMenu selectMenu={_selectMenu} curMenu={curMenu} />
                {(curMenu === 'Search') &&
                    <Search
                        location={location}
                        refreshMap={refreshMap}
                        showSidebar={showSidebar}
                        toggleSidebar={toggleSidebar}
                        keywordSearch={keywordSearch}
                        onTagSelect={onTagSelect}
                        onKeySelect={onKeySelect}
                    />
                }
                {(curMenu === 'Account') &&
                    <Account keyQuery={keyQuery} />
                }
            </section>
        </section>
    )
}

export default Sidebar;