/*global kakao*/

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';

import React from 'react';
import ReactDOM from "react-dom/client";
import ReactGA from 'react-ga';
import App from './App';
import './index.css';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import * as serviceWorker from './serviceWorker';

Sentry.init({
    dsn: "https://9ec653ccc4e54255b12d1ef2386f0452@sentry.io/1520766",
    integrations: [
        new Integrations.captureConsoleIntegration({
            levels: ['error', 'debug'],
        })
    ]
});

ReactGA.initialize('UA-146355571-1' /*, { debug: true }*/);

let script = document.createElement('script');
script.async = true;
script.src = "https://dapi.kakao.com/v2/maps/sdk.js?appkey=88ad4109566a02a9ea6fe07ffa5aaab0&libraries=services,clusterer&autoload=false";
document.head.appendChild(script);

const root = ReactDOM.createRoot(document.getElementById('root'));

script.onload = () => {
    kakao.maps.load(() => {
        window.OTKT.init({ app: 'teetime' });
        root.render(
            <App />
        );
    });
};

serviceWorker.unregister();