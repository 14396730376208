import React from 'react';

const SearchForm = (props) => {
    const [ keyword, setKeyword ] = React.useState('');
    const [ disabled, setDisabled ] = React.useState(true);

    const handleChange = (e) => {
        const _val = e.target.value;
        const _disabled = (_val.length > 1)? false : true;

        setKeyword(_val);
        setDisabled(_disabled);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (keyword.length > 1) {
            setDisabled(false);
            props.keywordSearch(keyword);
        }
    }

    return (
        <React.Fragment>
            <div className="search-form">
                <form onSubmit={handleSubmit}>
                    {/* <label className="label" htmlFor="search-keyword"></label> */}
                    <input type="text" aria-label="지역 및 상호명 검색" placeholder="지역/상호명 검색" id="search-keyword" name="keyword" onChange={handleChange} value={keyword} minLength="2" maxLength="15"></input>
                    <button type="submit" title="검색" disabled={disabled}><i className="fas fa-chevron-circle-right"></i></button>
                    {/* <button type="button" className={`btn btn-filter`}>필터</button> */}
                </form>
            </div>
        </React.Fragment>
    );
}

export default SearchForm;