import * as React from 'react';
import ReactGA from 'react-ga';
import ttContext from '../class/AppContext.jsx';

const SearchControl = (props) => {
    const { isLoading } = React.useContext(ttContext);
    const [ tags, setTags ] = React.useState({});
    const [ checked, setChecked ] = React.useState({ 
        parking: false, valet: false, price: false, review: false, teetime: false, qving: false, lesson: false,
        gzpark: false, gdrpark: false, gswing: false, sdr: false, udr: false, gts: false, tuprange: false,
        OD: false, TU: false, GZ: false, SG: false, XG: false, CT: false, GT: false,
    });

    const _onTagSelect = (e, key, val) => {
        if (isLoading) return;

        if (!(key in tags))
            tags[key] = [val];
        else if (!tags[key].includes(val))
            tags[key].push(val);
        else if (tags[key].includes(val))
            tags[key] = tags[key].filter(item => item !== val)
        
        if (tags[key].length === 0)
            delete tags[key];

        setChecked({ ...checked, [val] : e.target.checked });
        setTags(tags);
        props.onTagSelect(tags);

        if (e.target.checked)
            ReactGA.event({
                category: 'Search',
                action: 'onTagSelect',
                label: val
            });
    }

    return (
        <React.Fragment>
            <div className="search-control">
                {/* <button className="btn toggle" onClick={() => setShowControl(s => !s)} title="검색 필터 열기/접기">
                    { showControl && <i className="fas fa-caret-up"></i> }
                    { !showControl && <i className="fas fa-caret-down"></i> }
                </button> */}
                <ul className="ul-tags">
                    <li><input type="checkbox" id="cbTag1"  onChange={(e) => _onTagSelect(e, "_tags", "parking")}  checked={checked.parking} /><label htmlFor="cbTag1">주차</label></li>
                    <li><input type="checkbox" id="cbTag16" onChange={(e) => _onTagSelect(e, "_tags", "valet")}    checked={checked.valet} /><label htmlFor="cbTag16">발렛</label></li>
                    {/* <li><input type="checkbox" id="cbTag12" onChange={(e) => _onTagSelect(e, "_tags", "teetime")}  checked={checked.teetime} /><label htmlFor="cbTag12">스벙</label></li> */}
                    <li><input type="checkbox" id="cbTag8"  onChange={(e) => _onTagSelect(e, "_tags", "price")}    checked={checked.price} /><label htmlFor="cbTag8">그린피</label></li>
                    <li><input type="checkbox" id="cbTag10" onChange={(e) => _onTagSelect(e, "_tags", "review")}   checked={checked.review} /><label htmlFor="cbTag10">리뷰</label></li>
                    <li><input type="checkbox" id="cbTag24" onChange={(e) => _onTagSelect(e, "_tags", "lesson")}   checked={checked.lesson} /><label htmlFor="cbTag24">레슨</label></li>
                    {/* <li><input type="checkbox" id="cbTag17" onChange={(e) => _onTagSelect(e, "_tags", "247")}  checked={checked['247']} /><label htmlFor="cbTag17">24시간</label></li> */}
                </ul>
                {/* { showControl && */}
                    <React.Fragment>
                        <ul className="ul-tags ttype">
                            <li><input type="checkbox" id="cbTag13" onChange={(e) => _onTagSelect(e, "_tags", "gzpark")} checked={checked.gzpark} /><label className="gzpark" htmlFor="cbTag13">골프존파크</label></li>
                            <li><input type="checkbox" id="cbTag22" onChange={(e) => _onTagSelect(e, "_tags", "tuprange")} checked={checked.tuprange} /><label className="tuprange" htmlFor="cbTag22">프렌즈아카데미</label></li>
                            <li><input type="checkbox" id="cbTag14" onChange={(e) => _onTagSelect(e, "_tags", "gdrpark")} checked={checked.gdrpark} /><label className="gdrpark" htmlFor="cbTag14">GDR</label></li>
                            {/* <li><input type="checkbox" id="cbTag19" onChange={(e) => _onTagSelect(e, "_tags", "udr")}  checked={checked.udr} /><label className="udr" htmlFor="cbTag19">UDR</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag17" onChange={(e) => _onTagSelect(e, "_tags", "sdr")}  checked={checked.sdr} /><label className="sdr" htmlFor="cbTag17">SDR</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag23" onChange={(e) => _onTagSelect(e, "_tags", "qving")}  checked={checked.qving} /><label className="qving" htmlFor="cbTag23">큐빙</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag20" onChange={(e) => _onTagSelect(e, "_tags", "gts")}  checked={checked.gts} /><label className="gts" htmlFor="cbTag20">GTS</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag15" onChange={(e) => _onTagSelect(e, "_tags", "gswing")} checked={checked.gswing} /><label className="gswing" htmlFor="cbTag15">지스윙</label></li> */}
                        </ul>
                        <ul className="ul-tags ttype">
                            <li><input type="checkbox" id="cbTag11" onChange={(e) => _onTagSelect(e, "_ttype", "OD")}  checked={checked.OD} /><label className="od" htmlFor="cbTag11">실외연습장</label></li>
                            <li><input type="checkbox" id="cbTag5"  onChange={(e) => _onTagSelect(e, "_ttype", "GZ")}  checked={checked.GZ} /><label className="gz" htmlFor="cbTag5">골프존</label></li>
                            <li><input type="checkbox" id="cbTag4"  onChange={(e) => _onTagSelect(e, "_ttype", "TU")}  checked={checked.TU} /><label className="tu" htmlFor="cbTag4">카카오</label></li>
                            <li><input type="checkbox" id="cbTag6"  onChange={(e) => _onTagSelect(e, "_ttype", "SG")}  checked={checked.SG} /><label className="sg" htmlFor="cbTag6">SG골프</label></li>
                            {/* <li><input type="checkbox" id="cbTag7"  onChange={(e) => _onTagSelect(e, "_ttype", "XG")}  checked={checked.XG} /><label className="xg" htmlFor="cbTag7">X-GOLF</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag18" onChange={(e) => _onTagSelect(e, "_ttype", "CT")}  checked={checked.CT} /><label className="ct" htmlFor="cbTag18">시티존</label></li> */}
                            {/* <li><input type="checkbox" id="cbTag21" onChange={(e) => _onTagSelect(e, "_ttype", "GT")}  checked={checked.GT} /><label className="gt" htmlFor="cbTag21">GTS</label></li> */}
                        </ul>
                    </React.Fragment>
                {/* } */}
            </div>
        </React.Fragment>
    );
}

export default SearchControl;