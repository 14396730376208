import React from 'react';
import ReactGA from 'react-ga';
import { Route, Routes, useNavigate, useLocation } from "react-router";

import AppContext from '../class/AppContext.jsx'
import Sidebar from './Sidebar.jsx';
import StoreInfo from './StoreInfo.jsx';

const Main = (props) => {
    let navigate = useNavigate();
    let location = useLocation();

    const _kakaoMaps = React.useMemo(() => window.kakao.maps, []);
    const { backend, data, isLoading } = React.useContext(AppContext);

    const [isInitialized, setIsInitialized] = React.useState(false);

    const [_map, setMap] = React.useState(null);
    const [_clusterer, setClusterer] = React.useState(null);

    const [showSidebar, setShowSidebar] = React.useState(true);
    const [dataKey, setDataKey] = React.useState(null);
    const [dataIdx, setDataIdx] = React.useState(null);

    const [coords, setCoords] = React.useState([37.5665, 126.9780]);
    const [geolocation, setGeolocation] = React.useState('서울 중구');
    const [overlays, setOverlays] = React.useState({});
    const [marker, setMarker] = React.useState(null);

    ////////////////////////////////////////////////////////////////////////

    const toggleSidebar = () => setShowSidebar(s => !s);

    const onTagSelect = (tags) => {
        if (!data || data.length === 0)
            return;

        backend.filterData(data, tags);
    }

    const onKeyClear = () => {
        Object.keys(overlays).forEach((i) => {
            overlays[i].setZIndex(9);
            let className = overlays[i].getContent().className;
            overlays[i].getContent().className = className.replace(' selected', '');
        });

        setDataKey(null);
        setDataIdx(null);

        navigate('/');
    }

    const onKeySelect = (newDataKey) => {
        let newDataIdx = null;

        if (!newDataKey || !data) {
            console.error('onKeySelect', 'newDataKey not found');
            return;
        }

        if (dataKey && (newDataKey === dataKey))
            return;

        for (let i = 0; i < data.length; i++) {
            if (data[i]._id === newDataKey) {
                newDataIdx = i;
                break;
            }
        }

        if (newDataIdx === null || data[newDataIdx] === undefined) {
            console.error('onKeySelect', 'no newDataIdx', newDataKey);
            return;
        }

        Object.keys(overlays).forEach((i) => {
            overlays[i].setZIndex(9);
            let className = overlays[i].getContent().className;
            overlays[i].getContent().className = className.replace(' selected', '');
        });

        setDataKey(newDataKey);
        setDataIdx(newDataIdx);

        navigate(newDataKey);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const setBounds = () => {
        if (!overlays || Object.keys(overlays).length === 0)
            return;

        let bounds = new _kakaoMaps.LatLngBounds();

        Object.keys(overlays).forEach((k) => {
            bounds.extend(overlays[k].getPosition());
        });

        _map.setBounds(bounds);
        setOverlays(overlays);
    }

    const displayMarker = (kcoords) => {
        let content = document.createElement('div');
        content.className = 'overlay-marker';

        if (marker)
            marker.setPosition(kcoords);
        else
            setMarker(new _kakaoMaps.CustomOverlay({
                map: _map,
                position: kcoords,
                content: content,
                zIndex: 10
            }));
    }

    const displayPlace = (data, idx) => {
        if (!data || data._id === undefined || data._ttype === undefined || data.hidden === true)
            return;

        let zIndex = 9;
        const key = data._id;
        const ttype = data._ttype.toLowerCase();
        const _coords = new _kakaoMaps.LatLng(data._geo.geopoint._lat, data._geo.geopoint._long);

        let content = document.createElement('div');
        content.className = `overlay-info ${ttype}`;
        content.innerHTML = `<span class="title ${ttype}">${data._name}</span>`;
        content.setAttribute('dataKey', key);
        content.setAttribute('dataIdx', idx);

        if (data._tags && data._tags.includes("teetime")) {
            zIndex = 999;
            content.innerHTML += '<span class="badge bolt"><i class="fas fa-bolt"></i></span>';
        }

        let overlay = new _kakaoMaps.CustomOverlay({
            map: _map,
            position: _coords,
            content: content,
            zIndex: zIndex,
            clickable: true,
            yAnchor: 1,
        });
        overlays[key] = overlay;

        content.addEventListener('click', (_) => onKeySelect(key));
        content.addEventListener('mouseover', (_) => overlays[key].setZIndex(99));
        content.addEventListener('mouseout', (_) => overlays[key].setZIndex(9));

        _clusterer.addMarker(overlay);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const keywordSearch = (keyword) => {
        if (!keyword) return;

        new _kakaoMaps.services.Geocoder().addressSearch(keyword,
            (data, status) => {
                if (status === _kakaoMaps.services.Status.OK && data && data[0]) { // Address found
                    const _coords = [parseFloat(data[0].y), parseFloat(data[0].x)];
                    geoSearch(_coords);
                }
                else
                    backend.keywordSearch(keyword, setCoords); // Keyword search
            }
        );

        ReactGA.event({
            category: 'Search',
            action: 'keywordSearch',
            label: keyword
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const clearMap = () => {
        if (_clusterer)
            _clusterer.clear();

        Object.keys(overlays).forEach((key) => {
            overlays[key].setMap(null);
            delete overlays[key];
        });

        setOverlays({});
        setDataKey(null);
    }

    const mapZoom = (level) => _map.setLevel(_map.getLevel() + level);

    const mapPanTo = (key) => {
        navigate('/');

        setShowSidebar(false);

        _map.relayout();
        _map.setLevel(3);
        // _map.setDraggable(true);
        _map.panTo(overlays[key].getPosition());
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const geoSearch = (_coords) => {
        setCoords(_coords);
        backend.geoSearch({ center: _coords, radius: 2 });
    }

    const getGeoRegion = () => {
        const geocoder = new _kakaoMaps.services.Geocoder();
        geocoder.coord2Address(coords[1], coords[0],
            (data) => {
                if (data && data[0] && data[0].address) {
                    const addr = data[0].address;
                    setGeolocation(addr.region_1depth_name + ' ' + addr.region_2depth_name);
                }
            });
    }

    const getCurrentGeoPosition = () => {
        backend.getCurrentPosition()
            .then((_coords) => geoSearch(_coords))
            .catch(() => geoSearch(coords));
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const refreshMap = () => {
        if (!_map) return;

        navigate('/');

        let center = _map.getCenter();
        const _coords = [center.getLat(), center.getLng()];

        geoSearch(_coords);

        ReactGA.event({
            category: 'Click',
            action: 'refreshMap'
        });
    }

    const moveToCurrentPosition = () => {
        navigate('/');

        getCurrentGeoPosition();

        ReactGA.event({
            category: 'Click',
            action: 'moveToCurrentPosition'
        });
    }

    const keyQuery = (_id) => {
        backend.keySearch(_id)
            .then(() => {
                setDataKey(_id);
                setDataIdx(0);
            })
            .catch(() => navigate('/'))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    React.useEffect(() => {
        if (!_kakaoMaps) return;

        const locPosition = new _kakaoMaps.LatLng(coords[0], coords[1]);
        setMap(new _kakaoMaps.Map(document.getElementById("map"), { center: locPosition, level: 5 }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_kakaoMaps]);

    React.useEffect(() => {
        if (isInitialized) return;

        if (location.pathname.length > 1) {
            const _id = location.pathname.substring(1);
            keyQuery(_id);
        }
        else if (location.search) {
            const splits = location.search.split('=');
            if (decodeURI(splits[0]) === '?search' && splits[1].length > 1)
                keywordSearch(decodeURI(splits[1]));
        }
        else {
            getCurrentGeoPosition();
        }

        setIsInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!_map) return;

        _map.setCopyrightPosition(_kakaoMaps.CopyrightPosition.BOTTOMRIGHT, true);

        if (!_clusterer) {
            setClusterer(new _kakaoMaps.MarkerClusterer({
                map: _map,
                averageCenter: true,
                disableClickZoom: false,
                minLevel: 7,
                removeMarker: 1,
                styles: [{
                    width: '53px',
                    height: '53px',
                    background: '#BED0DC',
                    color: '#444',
                    textAlign: 'center',
                    lineHeight: '53px',
                    borderRadius: '100%',
                    fontWeight: '900',
                    borderColor: '#444'
                }]
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_map]);

    React.useEffect(() => {
        if (!_map || !coords) return;

        getGeoRegion();

        let locPosition = new _kakaoMaps.LatLng(coords[0], coords[1]);
        _map.setCenter(locPosition);
        displayMarker(locPosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords]);

    React.useEffect(() => {
        if (!_map || !data) return;

        clearMap();

        data.forEach((row, i) => displayPlace(row, i));
        setBounds();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (dataKey && overlays[dataKey]) {
            overlays[dataKey].getContent().className += ' selected';
            overlays[dataKey].setZIndex(9999);

            _map.setLevel(3);
            _map.panTo(overlays[dataKey].getPosition());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataKey]);

    // React.useEffect(() => {
    //     ReactGA.pageview(location.pathname);

    //     if (location.pathname === '/' && dataKey)
    //         onKeyClear();

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location.pathname]);

    //////////////////////////////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            <div id="www-contents">
                {isLoading &&
                    <div className="loading-overlay">
                        <div className="loading-wrap"><div className="icon-loading"></div></div>
                    </div>
                }
                <div className="contents-inner">
                    <Sidebar
                        refreshMap={refreshMap}
                        toggleSidebar={toggleSidebar}
                        keywordSearch={keywordSearch}
                        onTagSelect={onTagSelect}
                        onKeySelect={onKeySelect}
                        keyQuery={keyQuery}
                        location={geolocation}
                        showSidebar={showSidebar}
                    />
                    <Routes>
                        <Route
                            exact
                            path='/:id'
                            element={
                                <StoreInfo
                                    // {...props}
                                    mapPanTo={mapPanTo}
                                    onKeyClear={onKeyClear}
                                    data={data ? data[dataIdx] : null}
                                />
                            }
                        />
                    </Routes>
                    <section className={"section-map " + showSidebar} id="map"></section>
                    <div className="map-control">
                        <ul className="loc-control">
                            <li><button className="btn" onClick={refreshMap}><i className="fas fa-redo-alt"></i><span>다시 검색</span></button></li>
                            <li><button className="btn" onClick={moveToCurrentPosition}><i className="fas fa-map-marker-alt"></i><span>현재 위치로</span></button></li>
                            {/* <li><NavLink className="btn" to="/home"><i className="fas fa-home"></i></NavLink></li> */}
                        </ul>
                        <ul className="zoom-control">
                            <li><button className="btn in" onClick={() => mapZoom(-1)} title="지도 확대"><i className="fas fa-plus"></i></button></li>
                            <li><button className="btn out" onClick={() => mapZoom(1)} title="지도 축소"><i className="fas fa-minus"></i></button></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </React.Fragment>
    );
}

// const Footer = () => {     
//     return (    
//         <footer id="footer">
//             <div className="footer-modal">
//                 <span className="data">데이터 출처: 골프존,카카오VX,SGM,XPGA,시티존,GTS,UDR,YGQED,SDR</span>
//             </div>
//         </footer>
//     );
// };

export default Main;